import { thunk, Action, Thunk, action, Actions } from 'easy-peasy'
import {
  login,
  changePass,
  updateTeacher,
  getClass,
  postPhoto,
  updatUser,
  getUserData,
} from '../services/auth'
import jwt_decode from 'jwt-decode'
import { get, post } from '../services/http'
import {
  fetchMySchoolFeatures,
  unsubscribeTopicFirebase,
  fetchSchoolFeaturesBySchoolId,
  getSchoolLabelData,
} from '../services/school'
import { Computed, computed } from 'easy-peasy'

export let token: string | undefined = undefined

export function isAdmin(user) {
  return (
    user &&
    user.roles &&
    user.roles.find((r) => r.name === 'School Admin') !== undefined
  )
}

export interface UserRole {
  id: number
  name: string
  permissions: string[]
}

export interface Auth {
  user:
    | {
        id: number
        email: string
        firstName: string
        lastName: string
        address: string
        teacher: any
        student: any
        parent: any
        superAdmin: boolean
        phone: any
        username: string
        roles: UserRole[] | undefined
        school: any
      }
    | undefined
  isAdmin: Computed<Auth, boolean>
  isSchoolAdmin: boolean
  schoolFeatures: any
  jwt: string | undefined
  authenticate: Thunk<Auth, any>
  logout: Action<Auth, any>
  refreshFeatures: Thunk<Auth, void>
  checkAuth: Thunk<Auth>
  authenticated: Action<Auth, any>
  loginFailed: Action<Auth, string>
  setBusy: Action<Auth, boolean>
  error?: string
  busy?: boolean
  setChangePassRes: Action<Auth, any>
  passRes: string
  changePass: Thunk<Auth, {}>
  classTeacher: any
  setClassTeacher: Action<Auth, any>
  fetchTeacherClass: Thunk<Auth, any>
  uploadRes: boolean
  setUploadRes: Action<Auth, any>
  uploadPhoto: Thunk<Auth, {}>
  updateTeacherRes: boolean
  setUpdateTeacherRes: Action<Auth, any>
  updateTeacher: Thunk<Auth, {}>
  fetchSchoolFeatures: Thunk<Auth>
  userData: any
  fetchUserData: Thunk<Auth>
  setUserData: Action<Auth, any>
  fetchSchoolFeaturesBySchoolId: Thunk<Auth, number>
  setSchoolFeatures: Action<Auth, any>

  schoolLabel: any
  setSchoolLabel: Action<Auth, any>
  getSchoolLabelData: Thunk<Auth, number | undefined>

  schoolData: any
  setSchoolData: Action<Auth, any>

  
}

export const authStore: Auth = {
  user: undefined,
  isSchoolAdmin: false,
  jwt: undefined,
  busy: false,
  schoolFeatures: {},
  isAdmin: computed((auth) => isAdmin(auth.user)),
  refreshFeatures: thunk(async (actions) => {
    const decoded = jwt_decode(token as string) as { user }

    if (!(decoded.user.superAdmin || decoded.user.parent)) {
      const features = await fetchMySchoolFeatures()
      decoded['schoolFeatures'] = await features.json()
    }

    actions.authenticated(decoded)
  }),
  checkAuth: thunk(async (actions) => {
    const jwt = localStorage.getItem('jwt')
    if (jwt) {
      try {
        const decoded = jwt_decode(jwt) as { user }
        token = jwt

        const data = {}

        if (!(decoded.user.superAdmin || decoded.user.parent)) {
          const features = await fetchMySchoolFeatures()
          data['schoolFeatures'] = await features.json()
        }

        data['user'] = await (await get('/auth/me')).json()
        actions.authenticated(data)
      } catch (e) {
        console.error('[Auth] Failed to parse jwt')
      }
    }
  }),
  authenticate: thunk(async (actions, payload) => {
    const response = await login(payload)
    if (response.status === 201 || response.status === 200) {
      const body = await response.json()

      if (payload.remember) {
        localStorage.setItem('jwt', body.jwt)
      } else {
        localStorage.removeItem('jwt')
      }

      const decoded = jwt_decode(body.jwt) as { user }
      token = body.jwt

      if (!decoded.user.superAdmin) {
        const features = await fetchMySchoolFeatures()
        decoded['schoolFeatures'] = await features.json()
      }
      debugger
      actions.authenticated(decoded)
    } else {
      const body = await response.json()
      actions.loginFailed('Failed to login: ' + body.message)
    }
  }),
  fetchSchoolFeatures: thunk(async (actions, payload, { getStoreState }) => {
    const { auth } = getStoreState() as any
    const response = await fetchMySchoolFeatures()
    auth.schoolFeatures = await response.json()
  }),
  setBusy: action((state, isbusy) => {
    state.busy = isbusy
  }),
  logout: action((state) => {
    localStorage.removeItem('jwt')
    state.user = undefined
    state.busy = false
    state.error = ''
    window.location.reload()
    const fireToken = localStorage.getItem('fireToken')
    let token = {
      token: fireToken,
    }
    const response = unsubscribeTopicFirebase(token)
  }),
  authenticated: action((state, auth) => {
    state.user = auth.user
    state.schoolFeatures = auth.schoolFeatures
    state.isSchoolAdmin = auth.user?.roles?.some(
      (role) => role.name === 'School Admin'
    )
  }),
  loginFailed: action((state, message) => {
    state.busy = false
    state.error = message
  }),
  setChangePassRes: action((state, payload) => {
    state.passRes = payload
  }),
  passRes: '',
  changePass: thunk(async (actions, payload) => {
    const res = await changePass(payload)

    if (res.status === 201 || res.status === 200) {
      const data = await res.json()
      actions.setChangePassRes('true')
    }
    if (res.status === 404) {
      actions.setChangePassRes('false')
    }
  }),
  classTeacher: '',
  fetchTeacherClass: thunk(async (actions, payload) => {
    const res = await getClass(payload).then((res) => {
      return res.json()
    })
    actions.setClassTeacher(res)
  }),
  setClassTeacher: action((state, payload) => {
    state.classTeacher = payload
  }),

  uploadRes: false,
  setUploadRes: action((state, payload) => {
    state.uploadRes = payload
  }),
  uploadPhoto: thunk(async (actions, payload: any) => {
    const res = await postPhoto(payload.photo)
    if (res.status === 200 || res.status === 201) {
      const data = await res.json()
      const updateData = {
        id: payload.userId,
        photo: data.link,
      }
      const user = await updatUser(updateData)

      actions.setUploadRes(true)
    }
  }),
  updateTeacherRes: false,
  setUpdateTeacherRes: action((state, payload) => {
    state.updateTeacherRes = payload
  }),
  updateTeacher: thunk(
    async (actions, payload: { id: number; zoomLink: string }) => {
      const data = {
        zoomLink: payload.zoomLink,
      }
      const response = await updateTeacher(payload.id, data).then((res) => {
        return res.json()
      })
      //console.log(response)
      if (response.affected) {
        actions.setUpdateTeacherRes(true)
      }
    }
  ),

  userData: '',
  fetchUserData: thunk(async (actions) => {
    const res = await getUserData()
    if (res.status === 200 || res.status === 201) {
      const data = await res.json()
      actions.setUserData(data[0])
    }
  }),
  setUserData: action((state, payload) => {
    state.userData = payload
  }),
  fetchSchoolFeaturesBySchoolId: thunk(async (actions, schoolId) => {
    const res = await fetchSchoolFeaturesBySchoolId(schoolId)
    if (res.status === 200 || res.status === 201) {
      let data = await res.json()
      actions.setSchoolFeatures(data)
    }
  }),
  setSchoolFeatures: action((state, payload) => {
    state.schoolFeatures = payload
  }),
  schoolLabel: null,
  setSchoolLabel: action((state, payload) => {
    state.schoolLabel = payload
  }),
  getSchoolLabelData: thunk(async (actions, schoolId) => {
    const res = await getSchoolLabelData(schoolId)
    if (res.status === 200 || res.status === 201) {
      let data = await res.json()
      actions.setSchoolLabel(data)
    }
  }),
  schoolData: null,
  setSchoolData: action((state, payload) => {
    state.schoolData = payload
  }),
}

function enhanceUser(auth) {
  if (auth) {
    auth.user.isSchoolAdmin = auth?.user?.roles?.some(
      (i) => i.name === 'School Admin'
    )
  }
  return auth
}
