import * as React from 'react'
import { Suspense, useEffect, useState } from 'react'
import Recaptcha from 'react-google-invisible-recaptcha'
import './auth.scss'
import { Loading } from '../../Components/Loading'
import { login } from '../../services/auth'
import { Redirect } from 'react-router-dom'
import phone from './svg/phone.svg'
import mail from './svg/mail.svg'
import eyeopen from './svg/eyeo.svg'
import eyeclose from './svg/eyec.svg'
import jwt_decode from 'jwt-decode'
const SchoolLogo = React.lazy(() => import('./schoolLogo'))

declare module 'react' {
  interface HTMLAttributes<T> {
    align?: string
  }
}
export function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [checked, setChecked] = useState(true)
  const [busy, setBusy] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [unauthorized, setunauthorized] = useState(false)
  const [passType, setpassType] = useState(false)
  const [isParent, setisParent] = useState(false)
  const [schoolData, setSchoolData] = useState<any>(null)
  const [resolved, setResolved] = useState<boolean>(false)

  let recaptcha: any = React.createRef()

  useEffect(() => {
    setTimeout(() => {
      // console.log('preloading app..')
      import('../authenticated').then((imp) => {
        // console.log('Authenticated component preloaded')
      })
    }, 100)
  }, [])

  const handleSubmit = (event?: any) => {
    // console.log(username, password, checked)
    setBusy(true)
    login({ username, password }).then((resp) => {
      if (resp.status === 201) {
        resp.json().then((data) => {
          localStorage.setItem('jwt', data.jwt)
          let parentCheck: any = jwt_decode(data.jwt)
          if (parentCheck?.user?.parent != null) {
            setisParent(true)
          }
          setLoggedIn(true)
        })
      } else {
        setLoggedIn(false)
        setunauthorized(true)
        setBusy(false)
        setResolved(false)
      }
    })
    // if(event){
    //     event.preventDefault()
    // }
  }

  useEffect(() => {
    if (resolved) {
      handleSubmit()
    }
  }, [resolved])

  if (loggedIn) {
    return <Redirect to={isParent ? '/parent-dashboard' : '/'} />
  }

  const eyeClick = () => {
    setpassType(!passType)
  }

  function onCapResolved() {
    setResolved(true)
  }

  const onFormSubmit = async (e: any) => {
    e.preventDefault()
    if (schoolData && recaptcha && recaptcha.execute) {
      recaptcha.execute()
    } else {
      handleSubmit(e)
    }
  }

  return (
    <div className="body2">
      <div className="main">
        <p className="sign" align="center">
          <Suspense fallback={<Loading />}>
            <SchoolLogo setSchoolData={(data) => setSchoolData(data)} />
          </Suspense>
        </p>
        <form className="form1" onSubmit={onFormSubmit}>
          <input
            className="un "
            type="text"
            align="center"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            required={true}
          />
          <div className="eyes">
            <input
              className="pass"
              type={passType ? 'text' : 'password'}
              align="center"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required={true}
            />
            <span className="eyeShow">
              <img
                src={passType ? eyeclose : eyeopen}
                style={{ height: 20, width: 20, cursor: 'pointer' }}
                onClick={() => eyeClick()}
                alt="website home"
              />
            </span>
          </div>
          {unauthorized && (
            <div className="failedLogin">
              <span>Failed to login: Unauthorized</span>
            </div>
          )}
          <div className="rememberLogin">
            <label className="label1">
              <input
                name="isGoing"
                checked={checked}
                type="checkbox"
                onChange={(event) => {
                  setChecked(event.target.checked)
                }}
              />
              <span style={{ marginLeft: 3 }} className="textStyle">
                {' '}
                Remember me:{' '}
              </span>
            </label>
            <label className="label2">
              <input
                className="submit"
                type="submit"
                value={busy ? 'Submit...' : 'Submit'}
              />
            </label>
          </div>
          <hr
            style={{
              marginBottom: 5,
              marginTop: 20,
              color: 'rgba(0, 0, 0, 0.65)',
            }}
          />
          <div className="loginIcons">
            <div>
              <div className="icons">
                <img
                  src={mail}
                  style={{ height: 17, width: 17 }}
                  alt="website mail"
                />
                <span style={{ marginLeft: '5px' }} className="textStyle">
                  {' '}
                  {schoolData
                    ? schoolData.email
                    : 'contact@myclassroom.online '}
                </span>
              </div>
              <div className="icons">
                {schoolData ? (
                  schoolData?.config?.showPhoneNumberInLoginPage ? (
                    <img
                      src={phone}
                      style={{ height: 17, width: 17 }}
                      alt="website phone"
                    />
                  ) : null
                ) : (
                  <img
                    src={phone}
                    style={{ height: 17, width: 17 }}
                    alt="website phone"
                  />
                )}
                <span style={{ marginLeft: '5px' }} className="textStyle">
                  {' '}
                  {schoolData
                    ? schoolData?.config?.showPhoneNumberInLoginPage
                      ? schoolData.phone
                      : ''
                    : '+8801701-627477 '}
                </span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="footer" style={{ textAlign: 'left' }}></div>
      {schoolData?.config?.loginreCaptchaSiteKey ? (
        <Recaptcha
          ref={(ref) => (recaptcha = ref)}
          sitekey={schoolData.config.loginreCaptchaSiteKey} //{schoolData?.config?.registrationreCaptchaSiteKey} //"6Le6_0YbAAAAAM-r1_93sjLDF2Cf-I9alvnhSum9"
          onResolved={onCapResolved}
        />
      ) : null}
    </div>
  )
}
