import React, { Suspense, useContext } from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom'
import { Login } from './pages/auth/Login'
import './app.scss'
import { Loading } from './Components/Loading'
import 'katex/dist/katex.min.css'
const Authenticated = React.lazy(() => import('./pages/authenticated'))

function AuthCheck() {
    const jwt: any = localStorage.getItem('jwt')
    if (!jwt) {
        return <Redirect to="/login" />
    }

    return (
        <Suspense fallback={<Loading />}>
            <Authenticated />
        </Suspense>
    )
}

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route path="/">
                    <AuthCheck />
                </Route>
            </Switch>
        </Router>
    )
}

export default App
