import { post, get, patch, postFile } from "./http";

export interface AuthRequest {
  username: string;
  password: string;
}

export const login = (auth: AuthRequest) => {
  return post("/auth/login", auth);
};

export const changePass = (password) => {
  return post("/auth/changePass", password);
};

export const getClass = (id) => {
  return get(`/sections/teacher?id=${id}`);
};

// export const postPhoto = (id,payload,type) => {
//     return post(`/attachment/upload`, payload);
// };

export const updateTeacher = (id, payload) => {
  return patch(`/teachers/${id}`, payload);
};

export const postPhoto = ( payload) => {
  return postFile(`/attachment/profile`, payload);
};

export const updatUser = (payload) => {
  return patch("/auth", payload);
};

export const getUserData = () => {
  return get(`/auth`);
};
