import { get, post } from './http'

export const getSchools = () => get('/school')
export const getFeatures = () => get('/feature')
export const saveSchool = (school) => post('/school', school)
export const fetchMySchoolFeatures = () => get('/school/mySchoolFeatures')
export const awaitingConfirmations = (schoolId) =>
  get(`/school/awaitingConfirmations?schoolId=${schoolId}`)
export const confirmAwaitingUsers = (schoolId) =>
  post(`/school/confirmAwaitingUsers`, { schoolId })
export const findSchool = (schoolUrl) =>
  get(`/school-service/school-by-domain?subdomain=${schoolUrl}`)
export const unsubscribeTopicFirebase = (payload) =>
  post('/discussion/unsubscribe', payload)

export const fetchSchoolFeaturesBySchoolId = (schoolId) =>
  get(`/school/mySchoolFeaturesBySchoolId/?schoolId=${schoolId}`)

export const getSchoolLabelData = (schoolId) =>
  get(`/label-management/?schoolId=${schoolId}`)
